/*///////////////////////////////////////////////////*/
/*SAPITEC PAGE*/
/*App*/
.rec-carousel-item:focus {
  outline: none;
  box-shadow: inset 0 0 1px 0px violet;
}
.Carusello{
  animation: zerotohero 6s ease-in
}

div.fill {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

div.fill img {
  object-fit: fill;
  width: 100%;
  height: 100%;
}

.responsive{
  width: 100%;
  height: auto;
}
.fluttuo{
  transition: all .5s linear;
}

.App {
  font-family: sans-serif;
  text-align: center;
}
.carousel-inner img {
  margin: auto;
}
.bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-position: center;
  will-change: opacity;
}

/*1*/

.linea1{
  transform: rotate(90deg);
}
.App {
  text-align: center;
  background-color: transparent;
}
/*Logo navbar*/
.logo{
  background-image: url(./img/logosapitec.png);
  background-repeat: no-repeat;
  background-position: absolute;
  width: 170px;
  height: 50px;
  margin-right: 100px;
}

/*Testo navbar*/
.HomeT{
  color: white;
  margin-left: 30px;

}
.HomeTR{
  color: white;
  margin-right: 30px;
}

/*Counter-Up*/
.content{
  animation: float 6s ease-in infinite;
  border: 5px black;
  border-radius: 50%;
  width: 10%;
}
/*Text*/
.Animated{
  animation: floating 6s ease-in-out infinite;
}

/*/////////////////////////////////////////////////////*/

/*TESTO ANIMATO SAPITEC*/
/*Lettere S-A-P-I-T-E-C*/
.TestoSSS{
  animation: Refillable 1s ease-in-out forwards 3.2s;
}
.L1{ 
  stroke-dasharray: 515px;
  stroke-dashoffset: 515px;
  animation: line-anim 2s ease forwards;
}

.L2{
  stroke-dasharray: 165px;
  stroke-dashoffset: 165px;
  animation: line-anim 2s ease forwards 0.6s;
}

.L3{
  stroke-dasharray: 550px;
  stroke-dashoffset: 550px;
  animation: line-anim 2s ease forwards 0.9s;
}

.L4{
  stroke-dasharray: 495px;
  stroke-dashoffset: 550px;
  animation: line-anim 2s ease forwards 1.2s;
}

.L5{
  stroke-dasharray: 568px;
  stroke-dashoffset: 568px;
  animation: line-anim 2s ease forwards 1.5s;
}

.L6{
  stroke-dasharray: 606px;
  stroke-dashoffset: 606px;
  animation: line-anim 2s ease forwards 1.8s;
}

.L7{
  stroke-dasharray: 550px;
  stroke-dashoffset: 550px;
  animation: line-anim 2s ease forwards 2.1s;
 
}

/*//////////////////////////////////////////////////////////////////*/

/*TESTO ANIMATO YOUDOM*/
/*Posizione testo*/
.TestoYYY{
  animation: RefillableY 3s ease forwards 3.2s;
  height: 100%;
  width: 30%;
  background-color: transparent;
  float: none;
  margin-left: 35%;
  
}
/*Lettere Y-O-U-D-O-M*/
.Y1{ 
  stroke-dasharray: 515px;
  stroke-dashoffset: 515px;
  animation: line-anim 2s ease forwards;
}

.Y2{
  stroke-dasharray: 550px;
  stroke-dashoffset: 550px;
  animation: line-anim 2s ease forwards 0.6s;
}

.Y3{
  stroke-dasharray: 550px;
  stroke-dashoffset: 550px;
  animation: line-anim 2s ease forwards 0.9s;
}

.Y4{
  stroke-dasharray: 495px;
  stroke-dashoffset: 550px;
  animation: line-anim 2s ease forwards 1.2s;
}

.Y5{
  stroke-dasharray: 568px;
  stroke-dashoffset: 568px;
  animation: line-anim 2s ease forwards 1.5s;
}

.Y6{
  stroke-dasharray:  618px;
  stroke-dashoffset: 618px;
  animation: line-anim 2s ease forwards 1.8s;
}
/*///////////////////////////////////////////////////////////////////////*/
/*YOUDOM*/
/*Caselle Sezione 1*/

/*Bordo superiore caselle testo*/
.titleCY{
  text-align:center;
  background-color: rgb(66, 69, 255);
  color: black;
  border-radius: 10px 10px 0px  0px;
  box-shadow: 0px 12px 18px -6px rgba(6,6,6,6,.3);
  width: 100%;
  font-size: 2vw;

}
/*Divisore sezione 2*/
.p2Y{
  background-color: black;
}
.R{
  animation: zerotohero  ease forwards 6s;
}

/*///////////////////////////////////////////////////////////////////////*/
/*Scorrimento Top*/
.scroll-to-top{
  position: fixed;
  bottom: 50px;
  left: 50%;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-color: blue;
  color: blue;
  z-index: 1;
  background-image: url(./img/arrowup.jpg);
  }
.scroll-to-top .icon{
  margin-top: 10px;
  animation: scrollTop 0.5s alternate ease infinite;
}
/*///////////////////////////////////////////////////////////////////////*/


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
@keyframes float {
	0% {
		box-shadow: 0 5px 15px 0px rgba(168, 168, 168, 0.6);
		transform: translatey(0px);
	}
	50% {
		box-shadow: 0 25px 15px 0px rgba(168, 168, 168, 0.4);
		transform: translatey(-20px);
	}
	100% {
		box-shadow: 0 5px 15px 0px rgba(168, 168, 168, 0.6);
		transform: translatey(0px);
	}
}
@keyframes moving {
	0% {
    transform: translatex(-200px);
	}
	50% {
    transform: translatex(0px);

	}
	100% {
    transform: translatex(200px) ;
  }
}
@keyframes floating{
	0% {
		transform: translatey(0px);
	}
	50% {
		transform: translatey(-20px);
	}
	100% {
		transform: translatey(0px);
  }
}
@keyframes hideShow{
  0%{opacity: 0;}	

  50%{opacity: 1;}

 100%{opacity: 0;}
}
@keyframes zerotohero{
  0%{opacity: 0;}	

 100%{opacity: 1;}
}

@keyframes floatingLR {
	0% {
		transform: translatex(0px);
	}
	50% {
		transform: translatex(-20px);
	}
	100% {
		transform: translatex(0px);
	}
}
/*ANIMAZIONI TITOLI*/
/*Autocostruzione Titoli*/
@keyframes line-anim{
  to{
    color: rgb(255, 255, 255);
    stroke-dashoffset: 0;
  }
  
}
/*Riempimento titolo SAPITEC*/
@keyframes Refillable{
  0%{
    fill:transparent;
  }
  100%{
    fill: rgb(19, 4, 77);
  }
}
/*Riempimento titolo Youdom*/
@keyframes RefillableY{
  from{
    fill:transparent;
  }
  to{
    fill: rgb(0, 162, 255);
  }
}
/*Testo a comparsa*/
@keyframes Show{
  from{
    color:transparent;
  }
  to{
    color: white;
  }
}
/*Scorrimento automatico*/
@keyframes scrollTop{
  from{
    transform: translateY(2px);
  }
  to{
    transform: translateY(-1px)
  }
}
/*////////////////////////////////////////////////////////////////////////////////////*/

